import React, { forwardRef } from 'react'

const Input = forwardRef(
  ({ placeholder, type = 'text', name, isRequired = false, min, max }, ref) => {
    return (
      <input
        name={name}
        required={isRequired}
        className="block w-full h-6 tablet:h-5 px-2 py-1 my-2 rounded border border-gray-400 placeholder-gray-500"
        placeholder={placeholder}
        type={type}
        min={min}
        max={max}
        ref={ref}
      />
    )
  }
)

export default Input
